import React, {useState} from "react";
import { PageLayout, ArticleList } from "@components";
import { graphql } from "gatsby";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { BlueSquares1, BlueSquares2 } from "@assets/Background";
import * as styles from "../CommonResourcesOverview.module.scss";
import ArticleCard from "@components/ResourceCommomLayout/ArticleCard";
import LearnMoreSection from "@components/LearnMoreSection/LearnMoreSection";
import { useWindowSize } from "@helpers/Hooks";
import { CmsNode } from "@components/CMS/CmsTypes";


type CaseStudyProps = {
  data: {
    caseStudies: {
      nodes: CmsNode[];
    };
  };
};

export default function ResourcesCaseStudiesPage({ data }: CaseStudyProps) {
  let { width, isMobile } = useWindowSize();
  const [displayNum, setDisplayNum] = useState(5);

  const { caseStudies } = data;
  const firstCaseStudy = caseStudies.nodes[0];
  const articleList = caseStudies.nodes.slice(1);

  return (
    <PageLayout seoOptions={{ type: "PREDEFINED", pageName: "case-studies"}}>
      <BackgroundFadeContainer
        className={styles.top}
        withHeaderPadding
        type="blueFade"
        skewBottom
      >
        <BackgroundShapes
          leftShape={{ shape: <BlueSquares1 /> }}
          rightShape={{ shape: <BlueSquares2 /> }}
        >
          <div className={styles.caseStudiesFirstArticle}>
            <ArticleCard data={firstCaseStudy} />
          </div>
        </BackgroundShapes>
      </BackgroundFadeContainer>
      <BackgroundFadeContainer
        type="solidBlue"
        skewTop={!isMobile}
        skewBottom={width > 991}
        withHeaderPadding={isMobile}
      >
        <div className={styles.caseStudiesArticleList}>
          <ArticleList
            data={articleList}
            listTitle="Recent case studies"
            type="studies"
            displayNum={displayNum}
            setDisplayNum={setDisplayNum}
          />
        </div>
      </BackgroundFadeContainer>
      <LearnMoreSection
        className={styles.learnMore}
        textColor="ui-01"
        background="blueFade"
      />
    </PageLayout>
  );
}

export const pageQuery = graphql`
  {
    caseStudies: allNodeCaseStudy(sort: { order: DESC, fields: created }) {
      nodes {
        title
        path {
          alias
        }
        body {
          summary
        }
        created(formatString: "MMMM DD, YYYY")
        relationships {
          field_feature_image {
            relationships {
              field_media_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            field_media_image {
              alt
            }
          }
        }
      }
    }
  }
`;
